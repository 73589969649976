nav {
  width: 100%;
  height: 20vh;
  min-height: 200px;
  position: relative;
  z-index: 100;
}

.container-menu {
  width: 100%;
  height: 100px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.container-menu-desk {
  padding: 0 1%;
}

.container-logo-img {
  width: 220px;
  height: 100px;
  float: left;
}

.logo-img {
  width: 100%;
  height: 100%;
  background-image: url("../../img/logo.png");
  background-size: 70% 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.menu-desk {
  list-style: none;
  color: rgba(255, 255, 255, 0.85);
  display: block;
  float: right;
  line-height: 100px;
}

.menu-desk li {
  display: inline;
  margin: 50px;
  font-size: 1.5em;
  font-weight: 300;
  cursor: pointer;
}

.menu-desk li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}

.menu-desk li a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.menu-mobile {
  display: none;
  position: absolute;
  height: 100px;
  width: 95%;
}

.img-menu-mobile {
  display: none;
  width: 50px;
  height: 50px;
  color: white;
  background-size: 100% 100%;
  cursor: pointer;
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 100px;
  margin-right: 5%;
}

.container-menu-mobile {
  position: fixed;
  right: -80%;
  top: 0;
  height: 100%;
  width: 70%;
  z-index: 110;
  background-color: white;
  transition: 1000ms;
}

.menu-mobile-hover {
  position: absolute;
  list-style: none;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 99;
}

.menu-mobile-hover li a {
  text-decoration: none;
  color: #000000;
  font-size: 26px;
}

.menu-mobile-hover li {
  width: 100%;
  text-align: center;
  height: 80px;
}

.img-close-menu {
  width: 40px;
  height: 40px;
  color: #000000;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 20px;
  top: 35px;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .menu-desk {
    display: none;
  }

  .menu-mobile {
    display: block;
  }

  .img-menu-mobile {
    display: block;
  }
}

@media screen and (max-width: 350px) {
  .container-menu {
    width: 100%;
    padding: 0 10px;
  }

  .container-logo-img {
    width: 190px;
    height: 100px;
  }

  .logo-img {
    background-size: 100% 60%;
  }
}
