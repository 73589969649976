.div-lgpd{
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 20px;
  padding: 20px;
  background-color: #EBDFF4;
  color: #000000;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 99;
}

.div-lgpd a{
  color: #000000;

}

.div-lgpd button{
  background-color: #2C0548;
  color: #EBDFF4;
  border: 0;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
}

.div-lgpd button:hover{
  background-color: #2c0548ac;
}