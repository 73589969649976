.container-calc-title {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #ebdff4;
  border-radius: 10px 10px 0 0;
}

.container-calc-title a {
  text-decoration: none !important;
}

.container-title-calc {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: #ffff;
  border-radius: 10px 10px 0 0;
}

.container-title-calc span {
  width: 100%;
  text-align: center;
  color: #000000;
  font-size: 36px;
  font-style: regular;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.container-calc-pages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calc-pages {
  position: relative;
  width: 200px;
  height: 50px;
  border: 1px solid #ffff;
  background-color: #2c0548;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.calc-pages.active {
  background-color: #2c05489e;
}

.calc-pages:last-of-type {
  border-radius: 0 0 20px 0;
}

.calc-pages span {
  color: #eeeeee;
  font-style: regular;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 740px) {
  .container-title-calc span {
    font-size: 26px;
  }
  .calc-pages {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .calc-pages {
    width: 100%;
  }

  .calc-pages:last-of-type {
    border-radius: 0 0 0 0;
  }
}
