main {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-slide {
  width: 50%;
  display: flex;
  font-size: 1em;
  text-shadow: 6px 6px 6px #ffffff1e;
}

.text-slide h1 {
  font-weight: 700;
  font-style: normal;
  font-size: 4em;
  color: rgb(255, 255, 255);
  display: flex;
  padding-left: 5%;
  padding-bottom: 10%;
  align-items: center;
}

.img-slide {
  width: 50%;
  height: 100%;
  background-image: url("../../img/home-img.png");
  background-size: 90% 70%;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 770px) {
  main {
    flex-direction: column;
  }

  .text-slide {
    width: 100%;
    padding-right: 5%;
  }

  .text-slide h1 {
    font-size: 3em;
  }

  .img-slide {
    width: 100%;
    background-size: 95% 95%;
  }
}
