.div-footer{
  width: 100%;
  border-top: 2px solid #2C0548;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 10%;
  font-size: 12px;
}

.div-footer a {
  color: rgb(255, 255, 255);
  margin: 0 10px;
}

.footer-left span{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-left p {
  text-align: center;
}

.div-footer a:hover,
.footer-social-icon:hover{
  color: rgba(255, 255, 255, 0.367);
}

@media screen and (max-width: 570px) {
  .div-footer {
    flex-direction: column;
  }

  .footer-links{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

}