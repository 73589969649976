.container-div-calc {
  position: relative;
  width: 96%;
  background-color: #EBDFF4;
  margin: 0 auto;
  border-radius: 10px;
  
  margin-bottom: 150px;

  -webkit-box-shadow: 0px 0px 10px 1px rgb(0, 0, 0); 
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.53); 
}

.container-div-calc h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 28px;
  color: #000000;
  width: 100%;
  text-align: center;
  
}

@media screen and (max-width: 510px) {
  .container-div-calc {
      width: 100%;
  }
  .container-div-calc h1 {
      font-size: 20px;
  }
}