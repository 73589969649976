@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 2500px;
  margin: 0 auto;
  background: #0d0015;
}

body,
input,
button,
textarea {
  font-family: "Barlow", sans-serif;
  font: 400 1em "Barlow", sans-serif;
}

.clear {
  clear: both;
}

.container-div-page {
  position: relative;
  width: 90%;
  background-color: #EBDFF4;
  margin: 0 auto;
  border-radius: 10px;
  
  margin-bottom: 150px;


}

.container-div-page h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 28px;
  color: #000000;
  width: 100%;
  text-align: center;
  
}

@media screen and (max-width: 510px) {
  .container-div-page {
      width: 100%;
  }
  .container-div-page h1 {
      font-size: 20px;
  }
}
