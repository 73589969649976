.container-form {
  width: 98%;
  margin: 0 auto ;
  padding-bottom: 40px;
}

fieldset {
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.row-form {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.row-recaptcha{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.row-form-total {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.container-input {
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  position: relative;
}

.container-input-w100 {
  width: 100%;
  margin-bottom: 20px;
}

.container-input-w76 {
  width: 76%;
}

.container-input-w80 {
  width: 80%;
}

.container-input-w30 {
  width: 30%;
}

.container-input-w53 {
  width: 53%;
}

.container-input-w21 {
  width: 21.33%;
}

.container-input-w40 {
  width: 40%;
}

.container-input-w50 {
  width: calc(100% / 2);
}

.container-input-w20 {
  width: calc(100% / 5);
}

.container-input-w25 {
  width: calc(100% / 4);
}

.container-input-w33 {
  width: calc(100% / 3);
}

.container-input span {
  color: #000000;
  font-size: 0.95em;
  font-weight: 600;
  margin-left: 15px;
  line-height: 15px;
  margin-bottom: 3px;
  z-index: 1;
}

.span-help {
  margin-left: 35px !important;
  z-index: 1;
}

input, textarea {
  outline: none;
  border: none;
  background-color: transparent;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  padding: 20px;
}

input:focus, textarea :focus {
  outline: 2px solid #2c054855;
}

.input-control {
  width: 100%;
  height: 50px;
  border: 1px solid #2c0548;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 5px;
}

textarea {
  min-height: 200px!important;
  resize: vertical;
}

button{
  border: none;
}

.input-control-img{
  width: 26px;
  height: 26px;
  position: absolute;
  right: 10px;
  top: 35px;
  color: #2C0548;
  cursor: pointer;
}

.input-init-35 {
  padding-left: 35px;
}

.input-txt-ini {
  width: 20px;
  height: 50px;
  position: absolute;
  top: 22.5px;
  left: 10px;
  line-height: 50px;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.input-disabled {
  background-color: #2c0548;
  color: white;
  text-align: center;
}

.container-img-delete {
  position: relative;
  top: 28px;
  cursor: pointer;
}

.container-img-delete-inv {
  position: relative;
  top: 35px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
}

.container-calc {
  width: 44%;
  min-width: 400px;
  position: absolute;
  right: 0;
  display: flex;
}

.container-result {
  width: calc(100% - 40px);
  height: 50px;
  min-width: 300px;
  background-color: #2c0548;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}

.container-result p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.container-result p:nth-of-type(1) {
  margin-left: 30px;
  float: left;
}

.container-result p:nth-of-type(2) {
  float: right;
  margin-right: 30px;
}

.container-calc img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.container-help {
  position: absolute;
  top: 0;
  left: 15px;
  width: 15px;
  height: 15px;
  background-image: url("../img/help-web-button.png");
  background-size: 100% 100%;
  z-index: 10;
  margin-bottom: 3px;
}

.container-help:hover .hover-info {
  display: block;
}

.hover-info {
  width: 200px;
  padding: 20px;
  background-color: rgba(44, 5, 72, 0.952);
  color: white;
  border-radius: 20px;
  display: none;
  transition: 1000ms;
  position: absolute;
  top: 11px;
  left: 0px;
  font-size: 0.8em;
  text-align: justify;
  font-weight: 200;
}

.container-click-input-control {
  width: 100vw;
  height: 100vh;
  z-index: 110;
  background-color: rgba(255, 255, 255, 0.97);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
}

.container-click-input-control-div{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;
  height: 50%;
  margin: auto;
}

.container-click-input-control-close {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.form-btn{
  width: 150px;
  height: 50px;
  border-radius: 10px;
  background-color: #2c0548;
  color: white;
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  cursor: pointer;
}

.form-btn:hover{
  background-color: #2c0548e5;
}

.form-btn p {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1230px) {
  .row-form {
    flex-direction: column;

    border-radius: 20px;
  }

  .container-input-w76,
  .container-input-w30,
  .container-input-w53,
  .container-input-w21,
  .container-input-w33,
  .container-input-w20,
  .container-input-w40,
  .container-input-w80,
  .container-input-w50 {
    width: 100%;
    margin: 15px auto;
  }

  .container-img-delete {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 40px;
    min-height: 40px;
    z-index: 10;
  }
}

@media screen and (max-width: 570px) {
  .container-form {
    width: 100%;
  }
  .container-calc {
    position: relative;
    width: 100%;
    height: auto;
    right: auto;
    margin: 0 auto;
    min-width: 200px;
    margin-top: 20px;
  }

  .container-result {
    width: 100%;
    height: auto;
    padding: 5px;
    min-width: 200px;
    margin-top: 40px;
  }

  .container-result p {
    width: 100%;
    text-align: center;
    top: 0;
    transform: translateY(0);
  }

  .container-result p:nth-of-type(1) {
    width: 100%;
    float: none;
    margin-left: 0;
  }

  .container-result p:nth-of-type(2) {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .container-result-img {
    position: absolute;
    right: 0;
    top: -35px;
    transform: translateY(0);
    cursor: pointer;
    width: 100%;
    height: 50px;
    border: 2px solid #2c0548;
    border-radius: 20px;
    cursor: pointer;
  }

  .container-result-img img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .container-click-input-control-div {
    width: 100%;
    padding: 0 4%;
  }

  .container-click-input-control-close {
    top: -20px;
    right: 4%;
  }
}

@media screen and (max-width: 320px) {
  .hover-info {
    left: 0;
  }
}
