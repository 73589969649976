.container-text {
  width: 100%;
  padding: 2% 5%;
  min-height: 80vh;
  position: relative;
  color: white;
  font-weight: 400;
  font-size: 1.2em;
}

.container-text h1 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 2em;
  font-weight: 700;
}

.container-text h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.5em;
  font-weight: 700;
}

.container-text h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 700;
  color: white;
}

.container-text p {
  text-align: justify;
  margin: 10px auto;
}

.container-text a{
  color: white;
}

.container-text ul{
  margin-top: 10px;
  margin-left: 30px;
}


.container-text ul li{
  font-size: 1em;
}

