.container-info{
  width: 100%;
  margin: auto auto;
  padding: 4%;
  margin-bottom: 5%;
  border-top: 2px solid rgba(0, 0, 0, 0.53);
}

.container-info h1 {
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.container-info h2, h3 {
  color: #000000;
  margin-bottom: 5px;
}

.container-info h3, .p-h3 {
  margin-left: 5%;
}

.container-info p {
  color: #000000;
  text-align: justify;
  margin-bottom: 30px;
}

.info-img {
  width: 40%;
  min-width: 260px;
  height: 100px;
  margin: 30px auto;
  border-radius: 10px;
  background-image: url('./MARKUP.png');
  background-size: 100% 100%;
}

img{
  position: relative;
  display: flex;
  max-width: 100%;
  height: auto;
  margin: 30px auto;
  border-radius: 10px;
}

.container-info p ul{
  padding-left: 20px;
}

@media screen and (max-width: 510px) {
  .container-info {
      width: 100%;
  }

  .container-info h1 {
      font-size: 20px;
  }

  .container-info h2 {
    font-size: 18px;
}
}